$searchHeight: 3em;
$thumbWidth: 120px;
$padding: 8px;
$menubackground: #CCC9BF;
$buttoncolor: rgba(40, 60, 90, 1);
$buttona1: rgba(40, 60, 90, 0.9);
$buttona2: rgba(40, 60, 90, 0.4);
$buttona0: rgba(40, 60, 90, 0);

body{
    background-color:whitesmoke;
    font-size:1.3em;
}


button {
    user-select: none;
    cursor: pointer;
    display: inline-block;
    border: 1.5px solid $buttona1;
    color: $buttoncolor;
    padding: 6px 12px 6px 12px;
    background: $buttona0;
    &:hover {
        background: $buttona2;
    }
}
.col1 {
    width:600px;
}
textarea {
    box-sizing: border-box;
    width: 100%;
    resize:none;
    line-height:1.4em;
    padding:6px;
    height:9em;
}

.container{
//    display: flex;
    overflow-y:visible;
    height:100%;
}

#root {
    margin:32px auto;
 //   max-width:800px;
}

.controlbar {
    margin-bottom:18px;
    * {
        font-size:16px;
        margin-right:4px;
    }
    input {
        width: 450px;
    }  
}

.links a {
    display: block;
    margin-top:1em;
}

.icon_link {
   margin-top:.7em;
   span {
       margin-right:.7em;
       &:hover {
           color:darkred;
           cursor:pointer;
       }
   }
   button {
    font-size:16px;
       display:inline;
       text-decoration:underline;
       border:0;
       cursor:pointer;
       &:hover{
           background:transparent;
       }
   } 
}